import React from 'react'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'

const HeroContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: center;
`

const TitleContainer = styled.div`
  text-align: center;
`

const HeroTitle = styled.h1`
  font-weight: 700;
  font-size: 3rem;
  margin: 10px 30px;
  color: #fff;
  text-shadow: 1px 1px 4px rgba(34, 34, 34, 0.85);
  @media (max-width: 500px) {
    font-size: 2.2rem;
    margin: 10px 20px;
  }

  @media (max-width: 400px) {
    font-size: 2rem;
    margin: 10px 10px;
  }
`

const HeroSubTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 500;
  margin: 10px 50px;
  color: var(--color-white);
  text-shadow: 1px 1px 4px rgba(34, 34, 34, 0.85);
`

const Hero = props => {
  const { siteCover } = useSiteMetadata()
  const { fluid } = useSiteImages(siteCover)
  const heroImg = props.heroImg || fluid.src

  return (
    <HeroContainer style={{ backgroundImage: `url("${heroImg}")` }}>
      <TitleContainer>
        <HeroTitle>{props.title}</HeroTitle>
        {props.subTitle && <HeroSubTitle>{props.subTitle}</HeroSubTitle>}
      </TitleContainer>
    </HeroContainer>
  )
}

export default Hero